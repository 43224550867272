import { isBrowser, checkUtmSourceStartsWith } from "../../utils";

export function slots() {
  // Need for build version as window undefined
  if (!isBrowser()) {
    return [];
  }

  // Define Ad unit names
  let RightColumnUnitName = "RightColumn1";
  let LeaderDesktopInfiniteUnitName = "LeaderDesktopInfinite";
  let LeaderBelowTitleUnitName = "Leader_BellowTitle";
  let MPUCenterRightUnitName = "MPUCenterRight";
  let MPUTopRightUnitName = "MPUTopRight";
  let StickyBannerUnitName = "StickyBannerDesktop";
  let MPUParallaxUnitName = "MPU_Parallax";
  if (window.screen.width < 812) {
    StickyBannerUnitName = "StickyBannerMobile";
  }

  // Define Ad Unit Pathways
  let RightColumnPathway = "RightColumn1";
  let LeaderBelowTitlePathway = "Leader_BellowTitle";
  let LeaderDesktopInfinitePathway = "LeaderDesktopInfinite";
  let MPUCenterRightPathway = "MPUCenterRight";
  let MPUTopRightPathway = "MPUTopRight";
  let StickyBannerPathway = "StickyBannerDesktop";
  let MPUParallaxPathway = "MPU_Parallax";

  if (window.screen.width < 812) {
    StickyBannerPathway = "StickyBannerMobile";
  }

  // prettier-ignore
  let adUnits = [
    {
      "condition": window.screen.width > 990 && !checkUtmSourceStartsWith("fb-") && !checkUtmSourceStartsWith("twtr_"),
      "id": "RightColumn",
      "name": RightColumnUnitName,
      "path": `/76730613/${RightColumnPathway}`,
      "sizes": window.screen.width > 1100 ? [[300, 600], [160, 600],[300,250], [336,280], [320,50], [320,100], [300,100], [300,50], [320,480]] : [[160, 600]],
      "bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'motormag-weekly-com', adUnitElementId: 'RightColumn', environment:'desktop', placement:`${RightColumnPathway}` } },
        { bidder: 'adform', params: { mid: 1182039 } },
        //{ bidder: 'appnexus', params: {placementId: '23075410'} },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix', params: {siteId: 752388, size:[160, 600]} },
        ////{ bidder: 'justpremium', params: {zone: 133775, allow: ["is"]} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: {unit: '556399821', delDomain: 'lqr-d.openx.net'} },
        ////{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_desktop_MPUBelowNext' } },
        { bidder: 'sovrn', params:{ tagid: 972299 } },
        { bidder: 'sovrn', params:{ tagid: 972300 } },
        { bidder: 'teads', params: {pageId: '139117', placementId: '152653'} },
        { bidder: 'triplelift', params: { inventoryCode: 'MotormagCOM_desktop_RightColumnHalfPage1_Prebid' } },
        //{ bidder: 'yieldone', params: {placementId: '133362'} },
        { bidder: 'sharethrough', params: {pkey: 'QB3D6Er7I92VWuswhQczkPWv'} },
        { bidder: 'sharethrough', params: {pkey: '6WpywHKBq4G8Ms3ch8l7fRN9'} },
        { bidder: 'sharethrough', params: {pkey: 'CUcJVRfylPAUnP7soLU12OrT'} },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847481' } },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        { bidder: 'taboola', params: { tagId: 'RightColumn', publisherId: '1493286'} },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        { bidder: 'smartadserver', params: { siteId: 536189, pageId: 1662539, formatId: 116584} }
      ]
      
    },
    {
      "condition": checkStickybanner(),
      "id": "StickyBanner",
      "name": StickyBannerUnitName,
      "path": `/76730613/${StickyBannerPathway}`,
      "sizes": window.screen.width < 812 ? [[320, 50],[300,50],[360,50],[320,100],[300,100],[360,100]] : [[728, 90]],
      "bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'motormag-weekly-com', adUnitElementId: 'StickyBanner', environment:'desktop', placement:`${StickyBannerPathway}` } },
        { bidder: 'adform', params: { mid: 1182043 } },
       // { bidder: 'appnexus', params: {placementId: '23075411'} },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix', params: {siteId: 752389, size:[728, 90]} },
        ////{ bidder: 'justpremium', params: {zone: 133775, allow: ["is"]} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: {unit: '556399846', delDomain: 'lqr-d.openx.net'} },
        //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_desktop_MPUBelowNext' } },
        { bidder: 'sovrn', params:{ tagid: 972298 } },
        { bidder: 'teads', params: {pageId: '139117', placementId: '152653'} },
        { bidder: 'triplelift', params: { inventoryCode: 'MotormagCOM_desktop_StickyBanner_Prebid' } },
        //{ bidder: 'yieldone', params: {placementId: '133361'} },
        { bidder: 'sharethrough', params: {pkey: 'wD3NJmTeDc8Sb7PwboxNfyp9'} },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847490' } },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        { bidder: 'taboola', params: { tagId: 'StickyBanner', publisherId: '1493291'} },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        { bidder: 'smartadserver', params: { siteId: 536189, pageId: 1662539, formatId: 116616} }
      ],
      "mobile_bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'motormag-weekly-com', adUnitElementId: 'StickyBanner', environment:'mobile', placement:`${StickyBannerPathway}` } },
        { bidder: 'adform', params: { mid: 1182029 } },
       // { bidder: 'appnexus', params: { placementId: '23075461' } },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix', params: {siteId: 752400, size: [320,50]} },
        { bidder: 'ix', params: {siteId: 752400, size: [300,50]} },
        { bidder: 'ix', params: {siteId: 752400, size: [360,50]} },
        { bidder: 'ix', params: {siteId: 752400, size: [320,100]} },
        { bidder: 'ix', params: {siteId: 752400, size: [300,100]} },
        { bidder: 'ix', params: {siteId: 752400, size: [360,100]} },
        //{ bidder: 'justpremium', params: {zone: 133775, allow: ["ms"]} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: { unit: '556399825', delDomain: 'lqr-d.openx.net' } },
        //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_mobile_StickyBannerMobile' } },
        { bidder: 'sovrn', params:{ tagid: 972319 } },
        { bidder: 'sovrn', params:{ tagid: 972320 } },
        { bidder: 'sovrn', params:{ tagid: 972321 } },
        { bidder: 'teads', params: {pageId: '139117', placementId: '152653'} },
        { bidder: 'triplelift', params: {inventoryCode: 'MotormagCOM_mobile_StickyBanner_Prebid'} },
        //{ bidder: 'yieldone', params: {placementId: '133372'} },
        { bidder: 'sharethrough', params: {pkey: 'aDPNiyux9jmD6xACulGf0LeH'} },
        { bidder: 'sharethrough', params: {pkey: 'tEfylm8ldlwFt9rq5hIWT0pV'} },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847490' } },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        { bidder: 'taboola', params: { tagId: 'StickyBanner', publisherId: '1493291'} },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        { bidder: 'smartadserver', params: { siteId: 536189, pageId: 1662539, formatId: 116617} }
      ]
    },
    {
      "condition": window.screen.width < 812,
      "id": "MPUCenterRight",
      "name": MPUCenterRightUnitName,
      "path": `/76730613/${MPUCenterRightPathway}`,
      "sizes": [[300, 250], [320, 100], [336,280], [1,1]],
      "mobile_bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'motormag-weekly-com', adUnitElementId: 'MPUCenterRight', environment:'mobile', placement:`${MPUCenterRightPathway}` } },
        { bidder: 'adform', params: { mid: 1182027 } },
        //{ bidder: 'appnexus', params: { placementId: '23075454' } },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix',  params: { siteId: 752391, size: [300, 250] } },
        { bidder: 'ix',  params: { siteId: 752391, size: [336, 280] } },
        { bidder: 'ix',  params: { siteId: 752391, size: [320, 100] } },
        ////{ bidder: 'justpremium', params: {zone: 133775, allow: ["is"]} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: { unit: '556399823', delDomain: 'lqr-d.openx.net' } },
        //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_mobile_MPUCenterRight' } },
        { bidder: 'sovrn', params:{ tagid: 972328 } },
        { bidder: 'sovrn', params:{ tagid: 972329 } },
        { bidder: 'sovrn', params:{ tagid: 972330 } },
        { bidder: 'teads', params: {pageId: '139117', placementId: '152653'} },
        { bidder: 'triplelift', params: {inventoryCode: 'MotormagCOM_mobile_MPUCenterRight_Prebid'} },
        //{ bidder: 'yieldone', params: {placementId: '133375'} },
        { bidder: 'sharethrough', params: {pkey: 'cZ6IMdi4DKDEM0Vz2uLhKcBe'} },
        { bidder: 'sharethrough', params: {pkey: 'QmdyTtUCVgCvheg3ktIxLjEc'} },
        { bidder: 'sharethrough', params: {pkey: 'SkxbnVHNaxlbS2eGy9LwkZKM'} },
        //{ bidder: 'ogury', params: { assetKey: 'OGY-7462D4C3F58E', adUnitId: 'c87bdaa0-17e3-013b-a60f-4fbd3db95f28', skipSizeCheck: true } },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847490' } },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        { bidder: 'taboola', params: { tagId: 'MPUCenterRight', publisherId: '1493291'} },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        { bidder: 'smartadserver', params: { siteId: 536189, pageId: 1662539, formatId: 116603} }
      ]
    },
    {
      "condition": window.screen.width > 812,
      "id": "LeaderDesktopInfinite",
      "name": LeaderDesktopInfiniteUnitName,
      "path": `/76730613/${LeaderDesktopInfinitePathway}`,
      "sizes": [[728,90]],
      "bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'motormag-weekly-com', adUnitElementId: 'LeaderDesktopInfinite', environment:'desktop', placement:`${LeaderDesktopInfinitePathway}` } },
        { bidder: 'adform', params: { mid: 1182040 } },
       // { bidder: 'appnexus', params: {placementId: '23075357'} },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix', params: {siteId: 752379, size: [728, 90]} },
        ////{ bidder: 'justpremium', params: {zone: 133775, allow: ["is"]} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: {unit: '556399842', delDomain: 'lqr-d.openx.net'} },
        //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_desktop_MPUBelowNext' } },
        { bidder: 'sovrn', params:{ tagid: 972297 } },
        { bidder: 'teads', params: {pageId: '139117', placementId: '152653'} },
        { bidder: 'triplelift', params: {inventoryCode: 'MotormagCOM_desktop_LeaderDesktopInfinite_Prebid'} },
       // { bidder: 'yieldone', params: {placementId: '133360'} },
        { bidder: 'sharethrough', params: {pkey: 'hZx3rvafOiknh2Go45RhQM1b'} },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847481' } },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        { bidder: 'taboola', params: { tagId: 'LeaderDesktopInfinite', publisherId: '1493286'} },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        { bidder: 'smartadserver', params: { siteId: 536189, pageId: 1662539, formatId: 116570} }
      ],
    },
    {
      "condition": false,
      "id": "MPU_Parallax",
      "name": MPUParallaxUnitName,
      "path": `/76730613/${MPUParallaxPathway}`,
      "sizes": [[300,600], [300,250], [336,280], [320,50], [160,600], [120,600], [320,480]],
      "bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'motormag-weekly-com', adUnitElementId: 'MPU_Parallax', environment:'mobile', placement:`${MPUParallaxPathway}` } },
        { bidder: 'adform', params: { mid: 1182053 } },
       // { bidder: 'appnexus', params: { placementId: '23075453' } },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'openx', params: { unit: '556399856', delDomain: 'lqr-d.openx.net' } },
        { bidder: 'teads', params: {pageId: '139117', placementId: '152653'} },
        { bidder: 'ix', params: {siteId: 752392, size: [300,250]} },
        { bidder: 'ix', params: {siteId: 752392, size: [320,480]} },
        { bidder: 'ix', params: {siteId: 752392, size: [336,280]} },
        { bidder: 'ix', params: {siteId: 752392, size: [300,600]} },
        { bidder: 'ix', params: {siteId: 752392, size: [160,600]} },
        { bidder: 'ix', params: {siteId: 752392, size: [320,50]} },
        ////{ bidder: 'justpremium', params: {zone: 133775, allow: ["is"]} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'sovrn', params:{ tagid: 972314 }},
        { bidder: 'sovrn', params:{ tagid: 972315 }},
        { bidder: 'sovrn', params:{ tagid: 972316 }},
        { bidder: 'sovrn', params:{ tagid: 972317 }},
        { bidder: 'sovrn', params:{ tagid: 972318 }},
        //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_mobile_MPUInfiniteMobile' } },
        { bidder: 'triplelift', params: {inventoryCode: 'MotormagCOM_mobile_MPUParallax_Prebid'} },
        //{ bidder: 'yieldone', params: {placementId: '133371'} },
        { bidder: 'sharethrough', params: {pkey: 'SK1ojNpZASCzN5GrSKLZuwN0'} }, //160x600
        { bidder: 'sharethrough', params: {pkey: 'uzvY4bn2BQ9jjorZFUPajYKA'} }, //300x250
        { bidder: 'sharethrough', params: {pkey: 'wxSrdApmTNRGIPzrfnD8Yd6H'} }, //300x600
        { bidder: 'sharethrough', params: {pkey: 'QnJYFuHPTi6ktxPaWWbrczA0'} },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847481' } },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        { bidder: 'taboola', params: { tagId: 'MPU_Parallax', publisherId: '1493286'} },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        { bidder: 'smartadserver', params: { siteId: 536189, pageId: 1662539, formatId: 116593} }
      ],
    },
    {
      "condition": window.screen.width < 812 && !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_') && !checkUtmSourceStartsWith('fb-'),
      "id": "LeaderBelowTitle",
      "name": LeaderBelowTitleUnitName,
      "path": `/76730613/${LeaderBelowTitlePathway}`,
      "sizes": window.screen.width < 812 ? [[300, 250],[336,280],[320,100]] : [[728, 90]],
      "bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'motormag-weekly-com', adUnitElementId: 'LeaderBelowTitle', environment:'desktop', placement:`${LeaderBelowTitlePathway}` } },
        { bidder: 'adform', params: { mid: 1182021 } },
        //{ bidder: 'appnexus', params: { placementId: '23075358' } },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix', params: {siteId: 752372, size: [728,90] } },
        ////{ bidder: 'justpremium', params: {zone: 133775, allow: ["is"]} },
        { bidder: 'openx', params: { unit: '556399817', delDomain: 'lqr-d.openx.net'} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_desktop_Leader_bellowTitle' } },
        { bidder: 'sovrn', params:{ tagid: 972301 } },
        { bidder: 'teads', params: { pageId: '139117', placementId: '152653'} },
        { bidder: 'triplelift', params: {inventoryCode: 'MotormagCOM_desktop_Leader_bellowTitle_Prebid'} },
        //{ bidder: 'yieldone', params: {placementId: '133363'} },
        { bidder: 'sharethrough', params: {pkey: 'ahIUaXcPkiEFtTuTqmdRl8Ih'} },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847490' } },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        { bidder: 'taboola', params: { tagId: 'LeaderBelowTitle', publisherId: '1493291'} },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        { bidder: 'smartadserver', params: { siteId: 536189, pageId: 1662539, formatId: 116587} }
      ],
      "mobile_bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'motormag-weekly-com', adUnitElementId: 'LeaderBelowTitle', environment:'mobile', placement:`${LeaderBelowTitlePathway}` } },
        { bidder: 'adform', params: { mid: 1182026 } },
       // { bidder: 'appnexus', params: { placementId: '23075452' } },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix', params: {siteId: 752390, size: [300,250] } },
        { bidder: 'ix', params: {siteId: 752390, size: [336,280] } },
        { bidder: 'ix', params: {siteId: 752390, size: [320,100] } },
        ////{ bidder: 'justpremium', params: {zone: 133775, allow: ["is"]} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: { unit: '556399822', delDomain: 'lqr-d.openx.net'} },
        //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_mobile_Leader_bellowTitle' } },
        { bidder: 'sovrn', params:{ tagid: 972325 } },
        { bidder: 'sovrn', params:{ tagid: 972326 } },
        { bidder: 'sovrn', params:{ tagid: 972327 } },
        { bidder: 'teads', params: {pageId: '139117', placementId: '152653'} },
        { bidder: 'triplelift', params: {inventoryCode: 'MotormagCOM_mobile_Leader_bellowTitle_Prebid'} },
        //{ bidder: 'yieldone', params: {placementId: '127662'} },
        { bidder: 'sharethrough', params: {pkey: 'L6DpNWi1QmdUHd1vDbTzgf7r'} },
        { bidder: 'sharethrough', params: {pkey: 'RsjBpXK6GXih3dWWpaTjzTup'} },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847490' } },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        { bidder: 'taboola', params: { tagId: 'LeaderBelowTitle', publisherId: '1493286'} },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        { bidder: 'smartadserver', params: { siteId: 536189, pageId: 1662539, formatId: 116591} }
      ]
    },
    {
      "condition": window.screen.width < 812 && !checkUtmSourceStartsWith("fb-") && !checkUtmSourceStartsWith("twtr_"),
      "id": "MPUTopRight",
      "name": MPUTopRightUnitName,
      "path": `/76730613/${MPUTopRightPathway}`,
      "sizes": [[300, 250],[336,280],[320,100]],
      "mobile_bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'motormag-weekly-com',adUnitElementId: 'MPUTopRight', environment:'mobile', placement:`${MPUTopRightPathway}` } },
        { bidder: 'adform', params: { mid: 1182028 } },
        //{ bidder: 'appnexus', params: { placementId: '23075455' } },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix',  params: { siteId: 752393, size: [300, 250] } },
        { bidder: 'ix',  params: { siteId: 752393, size: [336, 280] } },
        { bidder: 'ix',  params: { siteId: 752393, size: [320, 100] } },
        ////{ bidder: 'justpremium', params: {zone: 133775, allow: ["is"]} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: { unit: '556399824', delDomain: 'lqr-d.openx.net' } },
        //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_mobile_MPUTopRight' } },
        { bidder: 'sovrn', params:{ tagid: 972322 } },
        { bidder: 'sovrn', params:{ tagid: 972323 } },
        { bidder: 'sovrn', params:{ tagid: 972324 } },
        { bidder: 'teads', params: { pageId: '139117', placementId: '152659'} },
        { bidder: 'triplelift', params: {inventoryCode: 'MotormagCOM_mobile_MPUTopRight_Prebid'} },
       // { bidder: 'yieldone', params: {placementId: '133373'} },
        { bidder: 'sharethrough', params: {pkey: 'kjXuuTlOhwUQAPvV105P026L'} },
        { bidder: 'sharethrough', params: {pkey: 'iYqhkM5eJeBa9zGLtFNyEVjR'} },
        { bidder: 'sharethrough', params: {pkey: 'i1jMuVeSukEUN6uRf7glHDru'} },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847481' } },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        { bidder: 'taboola', params: { tagId: 'MPUTopRight', publisherId: '1493286'} },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        { bidder: 'smartadserver', params: { siteId: 536189, pageId: 1662539, formatId: 116594} }
      ]
    },
  ];

  /**
   * Finds left and right columns, pushes the missing bidders
   * and pushes correct bidders to the adunit
   */
  // prettier-ignore
  if (window.screen.width > 1100) {
      let rcMissingIX = adUnits.find(ad => ad.id === 'RightColumn');
      // Right
      rcMissingIX.bidders.push(
        { bidder: 'ix', params: {siteId: 752388, size:[300, 600]} },
        { bidder: 'ix', params: {siteId: 752388, size:[300, 250]} },
        { bidder: 'ix', params: { siteId: 752388, size: [300, 600]} },
        { bidder: 'ix', params: { siteId: 752388, size: [300, 250]} },
        { bidder: 'ix', params: { siteId: 752388, size: [336, 280] }},
        { bidder: 'ix', params: { siteId: 752388, size: [320, 50] }},
        { bidder: 'ix', params: { siteId: 752388, size: [320, 100] }},
        { bidder: 'ix', params: { siteId: 752388, size: [300, 100] }},
        { bidder: 'ix', params: { siteId: 752388, size: [300, 50] }},
        { bidder: 'ix', params: { siteId: 752388, size: [320, 480] }} 
      )
    }

  return adUnits;

  function checkStickybanner() {
    if (
      !(checkUtmSourceStartsWith("fb-") && !checkUtmSourceStartsWith("twtr_"))
    ) {
      return window.screen.width < 812;
    } else {
      return false;
    }
  }
}

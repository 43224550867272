import { isBrowser, checkUtmSourceStartsWith } from "../../utils";

export function slots() {
  // Need for build version as window undefined
  if (!isBrowser()) {
    return [];
  }

  // prettier-ignore
  const bidders = [
    // { bidder: 'adagio', params: { organizationId:'1067', site:'motormag-weekly-com', adUnitElementId: 'LeaderBelowTitle', environment:'desktop', placement:`${LeaderBelowTitlePathway}` } },
    // { bidder: 'adform', params: { mid: 1182007 } },
    // //{ bidder: 'appnexus', params: { placementId: '23075382' } },
    // //{ bidder: 'justpremium', params: {zone: 133775} },
    // { bidder: 'openx', params: { unit: '556399831', delDomain: 'lqr-d.openx.net'} },
    // { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
    // //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_desktop_Leader_bellowTitle' } },
    // { bidder: 'sovrn', params:{ tagid: 972312 } },
    // { bidder: 'teads', params: { pageId: '139117', placementId: '152653'} },
    // { bidder: 'triplelift', params: { inventoryCode: 'MotormagCOM_desktop_NEXT_Leader_bellowTitle_Prebid' } },
    // //{ bidder: 'yieldone', params: {placementId: '133369'} },
    // { bidder: 'sharethrough', params: {pkey: 'd1VS2AhMMVEaoHZXWx9yZ4LL'} },
    // { bidder: 'sharethrough', params: {pkey: 'Ad2dlzALC7ePFb9JX4T5Ib4R'} },
    // { bidder: 'smartadserver', params: { siteId: 536189, pageId: 1662539, formatId: 116575} },
    // { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847481' } },
    // { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
    // { bidder: 'taboola', params: { tagId: 'LeaderBelowTitle', publisherId: '1493286'} },
    // { bidder: 'rise', params: { org: '62050c0300be360001986a59' } }
  ];

  // prettier-ignore
  const mobileBidders = [
    // { bidder: 'adagio', params: { organizationId:'1067', site:'motormag-weekly-com', adUnitElementId: 'LeaderBelowTitle', environment:'mobile', placement:`${LeaderBelowTitlePathway}` } },
    // { bidder:  'adform', params: { mid: 1182013 } },
    // //{ bidder: 'appnexus', params: { placementId: '23075456' } },
    // //{ bidder: 'justpremium', params: {zone: 133775} },
    // { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
    // { bidder: 'openx', params: { unit: '556399836', delDomain: 'lqr-d.openx.net'} },
    // //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_mobile_Leader_bellowTitle' } },
    // { bidder: 'sovrn', params:{ tagid: 972331 } },
    // { bidder: 'sovrn', params:{ tagid: 972332 } },
    // { bidder: 'sovrn', params:{ tagid: 972333 } },
    // { bidder: 'teads', params: {pageId: '139117', placementId: '152654'} },
    // { bidder: 'triplelift', params: {inventoryCode: 'MotormagCOM_mobile_NEXT_Leader_belowTitle_Prebid'} },
    // //{ bidder: 'yieldone', params: {placementId: '133376'} },
    // { bidder: 'sharethrough', params: {pkey: 'SoAz30yVY7xYis0Jmz0Jbyhl'} },
    // { bidder: 'sharethrough', params: {pkey: 'd1VS2AhMMVEaoHZXWx9yZ4LL'} },
    // { bidder: 'sharethrough', params: {pkey: 'Ad2dlzALC7ePFb9JX4T5Ib4R'} },
    // { bidder: 'smartadserver', params: { siteId: 536189, pageId: 1662539, formatId: 116595} },
    // { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847481' } },
    // { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
    // { bidder: 'taboola', params: { tagId: 'LeaderBelowTitle', publisherId: '1493286'} },
    // { bidder: 'rise', params: { org: '62050c0300be360001986a59' } }
  ];
  // prettier-ignore
  let adUnits = [
      {
        // "condition": !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
        // "id": "LeaderBelowTitle",
        // "name": "NEXT_Leader_BellowTitle",
        // "path": "/76730613/NEXT_Leader_BellowTitle",
        // "sizes": window.screen.width < 812 ? [[300, 250],[336,280],[320,100]] : [[728, 90]],
        // "bidders": bidders,
        // "mobile_bidders": mobileBidders,
      },
      {
        "condition": !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
        "id": "LeaderBelowTitle_1",
        "name": "NEXT_Leader_BellowTitle",
        "path": "/76730613/NEXT_Leader_BellowTitle",
        "sizes": window.screen.width < 812 ? [[300, 250],[336,280],[320,100]] : [[728, 90]],
        "bidders": bidders,
        "mobile_bidders": mobileBidders,
      },
      {
        "condition": !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
        "id": "LeaderBelowTitle_2",
        "name": "NEXT_Leader_BellowTitle",
        "path": "/76730613/NEXT_Leader_BellowTitle",
        "sizes": window.screen.width < 812 ? [[300, 250],[336,280],[320,100]] : [[728, 90]],
        "bidders": bidders,
        "mobile_bidders": mobileBidders,
      },
      {
        "condition": !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
        "id": "LeaderBelowTitle_4",
        "name": "NEXT_Leader_BellowTitle",
        "path": "/76730613/NEXT_Leader_BellowTitle",
        "sizes": window.screen.width < 812 ? [[300, 250],[336,280],[320,100]] : [[728, 90]],
        "bidders": bidders,
        "mobile_bidders": mobileBidders,
      },
      {
        "condition": window.screen.width > 812,
        "id": "RightColumn",
        "name": "NEXT_RightColumnHalfPage1",
        "path": "/76730613/NEXT_RightColumnHalfPage1",
        "sizes": window.screen.width > 1440 ? [[300, 600], [160, 600]] : [[160, 600]],
        "bidders": [
          // { bidder: 'adagio', params: { organizationId:'1067', site:'motormag-weekly-com', adUnitElementId: 'RightColumn', environment:'desktop', placement:`${RightColumnPathway}` } },
          // { bidder: 'adform', params: { mid: 1182038 } },
          // //{ bidder: 'appnexus', params: {placementId: '23075408'} },
          // //{ bidder: 'justpremium', params: {zone: 133775} },
          // { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
          // { bidder: 'openx', params: {unit: '556399835', delDomain: 'lqr-d.openx.net'} },
          // // //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_desktop_MPUBelowNext' } },
          // { bidder: 'sovrn', params:{ tagid: 972304 } },
          // { bidder: 'sovrn', params:{ tagid: 972305 } },
          // { bidder: 'teads', params: {pageId: '139117', placementId: '152653'} },
          // { bidder: 'triplelift', params: { inventoryCode: 'MotormagCOM_desktop_NEXT_RightColumnHalfPage1_Prebid' } },
          // //{ bidder: 'yieldone', params: {placementId: '133365'} },
          // { bidder: 'sharethrough', params: {pkey: '3RC9iI5xFzB8M2qvdgawB80U'} },
          // { bidder: 'smartadserver', params: { siteId: 536189, pageId: 1662539, formatId: 116582} },
          // { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847481' } },
          // { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
          // { bidder: 'taboola', params: { tagId: 'RightColumn', publisherId: '1493286'} },
          // { bidder: 'rise', params: { org: '62050c0300be360001986a59' } }
        ]
      }
    ];
  return adUnits;
}

import { isBrowser, checkUtmSourceStartsWith } from "../../utils";

export function slots() {
  // Need for build version as window undefined
  if (!isBrowser()) {
    return [];
  }

  // Define Ad unit names
  let RightColumnUnitName = "NEXT_RightColumnHalfPage1";
  let LeftColumnUnitName = "NEXT_LeftColumnHalfPage";
  let MPUCenterRightUnitName = "NEXT_MPUCenterRight";
  let MPUTopRightUnitName = "NEXT_MPUTopRight";
  let LeaderBelowTitleUnitName = "NEXT_Leader_BellowTitle";
  let StickyBannerUnitName = "NEXT_StickyBannerDesktop";
  let MPUBelowNextUnitName = "NEXT_MPUBelowNext";
  if (window.screen.width < 812) {
    StickyBannerUnitName = "NEXT_StickyBannerMobile";
  }

  // Define Ad Unit Pathways
  let RightColumnPathway = "NEXT_RightColumnHalfPage1";
  let LeftColumnPathway = "NEXT_LeftColumnHalfPage";
  let MPUCenterRightPathway = "NEXT_MPUCenterRight";
  let MPUTopRightPathway = "NEXT_MPUTopRight";
  let LeaderBelowTitlePathway = "NEXT_Leader_BellowTitle";
  let StickyBannerPathway = "NEXT_StickyBannerDesktop";
  let MPUBelowNextPathway = "NEXT_MPUBelowNext";
  if (window.screen.width < 812) {
    StickyBannerPathway = "NEXT_StickyBannerMobile";
  }

  // prettier-ignore
  let adUnits = [
      {
        "condition": !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
        "id": "LeaderBelowTitle",
        "name": LeaderBelowTitleUnitName,
        "path": `/76730613/${LeaderBelowTitlePathway}`,
        "sizes": window.screen.width < 812 ? [[300, 250],[336,280],[320,100]] : [[728, 90]],
        "bidders": [
          { bidder: 'adagio', params: { organizationId:'1067', site:'motormag-weekly-com', adUnitElementId: 'LeaderBelowTitle', environment:'desktop', placement:`${LeaderBelowTitlePathway}` } },
          { bidder: 'adform', params: { mid: 1182007 } },
          //{ bidder: 'appnexus', params: { placementId: '23075382' } },
          { bidder: 'criteo', params: { networkId: 8147 } },
          { bidder: 'ix', params: {siteId: 752382, size: [728,90] } },
          ////{ bidder: 'justpremium', params: {zone: 133775} },
          //{ bidder: 'openx', params: { unit: '556399831', delDomain: 'lqr-d.openx.net'} },
          { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
          //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_desktop_Leader_bellowTitle' } },
          { bidder: 'sovrn', params:{ tagid: 972312 } },
          { bidder: 'teads', params: {pageId: '139117', placementId: '152653'} },
          { bidder: 'triplelift', params: { inventoryCode: 'MotormagCOM_desktop_NEXT_Leader_bellowTitle_Prebid' } },
          //{ bidder: 'yieldone', params: {placementId: '133369'} },
          { bidder: 'sharethrough', params: {pkey: 'd1VS2AhMMVEaoHZXWx9yZ4LL'} },
          { bidder: 'sharethrough', params: {pkey: 'Ad2dlzALC7ePFb9JX4T5Ib4R'} },
          { bidder: 'smartadserver', params: { siteId: 536189, pageId: 1662539, formatId: 116575} },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847481' } },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
          { bidder: 'taboola', params: { tagId: 'LeaderBelowTitle', publisherId: '1493286'} },
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' } }
        ],
        "mobile_bidders": [
          { bidder: 'adagio', params: { organizationId:'1067', site:'motormag-weekly-com', adUnitElementId: 'LeaderBelowTitle', environment:'mobile', placement:`${LeaderBelowTitlePathway}` } },
          { bidder:  'adform', params: { mid: 1182013 } },
          //{ bidder: 'appnexus', params: { placementId: '23075456' } },
          { bidder: 'criteo', params: { networkId: 8147 } },
          { bidder: 'ix', params: {siteId: 752395, size: [300,250] } },
          { bidder: 'ix', params: {siteId: 752395, size: [336,280] } },
          { bidder: 'ix', params: {siteId: 752395, size: [320,100] } },
          ////{ bidder: 'justpremium', params: {zone: 133775} },
          { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
          { bidder: 'openx', params: { unit: '556399836', delDomain: 'lqr-d.openx.net'} },
          //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_mobile_Leader_bellowTitle' } },
          { bidder: 'sovrn', params:{ tagid: 972331 } },
          { bidder: 'sovrn', params:{ tagid: 972332 } },
          { bidder: 'sovrn', params:{ tagid: 972333 } },
          { bidder: 'teads', params: {pageId: '139117', placementId: '152653'} },
          { bidder: 'triplelift', params: {inventoryCode: 'MotormagCOM_mobile_NEXT_Leader_belowTitle_Prebid'} },
          //{ bidder: 'yieldone', params: {placementId: '133376'} },
          { bidder: 'sharethrough', params: {pkey: 'SoAz30yVY7xYis0Jmz0Jbyhl'} },
          { bidder: 'sharethrough', params: {pkey: 'd1VS2AhMMVEaoHZXWx9yZ4LL'} },
          { bidder: 'sharethrough', params: {pkey: 'Ad2dlzALC7ePFb9JX4T5Ib4R'} },
          { bidder: 'smartadserver', params: { siteId: 536189, pageId: 1662539, formatId: 116595} },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847481' } },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
          { bidder: 'taboola', params: { tagId: 'LeaderBelowTitle', publisherId: '1493286'} },
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' } }
        ]
      },
      {
        "condition": window.screen.width < 812,
        "id": "MPUTopRight",
        "name": MPUTopRightUnitName,
        "path": `/76730613/${MPUTopRightPathway}`,
        "sizes": [[300, 250],[336,280],[320,100]],
        "mobile_bidders": [
          { bidder: 'adagio', params: { organizationId:'1067', site:'motormag-weekly-com', adUnitElementId: 'MPUTopRight', environment:'mobile', placement:`${MPUTopRightPathway}` } },
          { bidder: 'adform', params: { mid: 1182015 } },
          //{ bidder: 'appnexus', params: { placementId: '23075460' } },
          { bidder: 'criteo', params: { networkId: 8147 } },
          { bidder: 'ix',  params: { siteId: 752397, size: [300, 250] } },
          { bidder: 'ix',  params: { siteId: 752397, size: [336, 280] } },
          { bidder: 'ix',  params: { siteId: 752397, size: [320, 100] } },
          ////{ bidder: 'justpremium', params: {zone: 133775} },
          { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
          { bidder: 'openx', params: { unit: '556399838', delDomain: 'lqr-d.openx.net' } },
          //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_mobile_MPUTopRight' } },
          { bidder: 'sovrn', params:{ tagid: 972336 } },
          { bidder: 'sovrn', params:{ tagid: 972337 } },
          { bidder: 'sovrn', params:{ tagid: 972338 } },
          { bidder: 'teads', params: {pageId: '139117', placementId: '152653'} },
          { bidder: 'triplelift', params: {inventoryCode: 'MotormagCOM_mobile_NEXT_MPUTopRight_Prebid'} },
          //{ bidder: 'yieldone', params: {placementId: '133378'} },
          { bidder: 'sharethrough', params: {pkey: '2GgwCSI8CkfNJdFRdxYWntAN'} },
          { bidder: 'sharethrough', params: {pkey: 'WxDusIQ31ZfhhDWyePVFGVHf'} },
          { bidder: 'sharethrough', params: {pkey: 'XXk5LSINw2Bk3F5E9GeirtS9'} },
          { bidder: 'smartadserver', params: { siteId: 536189, pageId: 1662539, formatId: 116599} },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847481' } },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
          { bidder: 'taboola', params: { tagId: 'MPUTopRight', publisherId: '1493286'} },
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' } }
        ]
      },
      {
        "condition": window.screen.width > 1023,
        "id": "RightColumn",
        "name": RightColumnUnitName,
        "path": `/76730613/${RightColumnPathway}`,
        "sizes": window.screen.width > 1440 ? [[300, 600], [160, 600], [300, 250]] : [[160, 600]],
        "bidders": [
          { bidder: 'adagio', params: { organizationId:'1067', site:'motormag-weekly-com', adUnitElementId: 'RightColumn', environment:'desktop', placement:`${RightColumnPathway}` } },
          { bidder: 'adform', params: { mid: 1182038 } },
          //{ bidder: 'appnexus', params: {placementId: '23075408'} },
          { bidder: 'criteo', params: { networkId: 8147 } },
          { bidder: 'ix', params: {siteId: 752386, size:[160, 600]} },
          ////{ bidder: 'justpremium', params: {zone: 133775} },
          { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
          { bidder: 'openx', params: {unit: '556399835', delDomain: 'lqr-d.openx.net'} },
          // //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_desktop_MPUBelowNext' } },
          { bidder: 'sovrn', params:{ tagid: 972304 } },
          { bidder: 'sovrn', params:{ tagid: 972305 } },
          { bidder: 'teads', params: {pageId: '139117', placementId: '152653'} },
          { bidder: 'triplelift', params: { inventoryCode: 'MotormagCOM_desktop_NEXT_RightColumnHalfPage1_Prebid' } },
          //{ bidder: 'yieldone', params: {placementId: '133365'} },
          { bidder: 'sharethrough', params: {pkey: '3RC9iI5xFzB8M2qvdgawB80U'} },
          { bidder: 'sharethrough', params: {pkey: 'ZhgP3SpakHKl3Gb7KuznPgm5'} },
          { bidder: 'sharethrough', params: {pkey: 'e0qC1fiYFKd9mEDJ6duyyVs0'} },
          { bidder: 'smartadserver', params: { siteId: 536189, pageId: 1662539, formatId: 116582} },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847481' } },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
          { bidder: 'taboola', params: { tagId: 'RightColumn', publisherId: '1493286'} },
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' } }
        ]
      },
      {
        "condition": window.screen.width > 812 && !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
        "id": "LeftColumn",
        "name": LeftColumnUnitName,
        "path": `/76730613/${LeftColumnPathway}`,
        "sizes": window.screen.width > 1440 ? [[300, 600], [160, 600], [300, 250]] : [[160, 600]],
        "bidders": [
          { bidder: 'adagio', params: { organizationId:'1067', site:'motormag-weekly-com', adUnitElementId: 'LeftColumn', environment:'desktop', placement:`${LeftColumnPathway}` } },
          { bidder: 'adform', params: { mid: 1182045 } },
          //{ bidder: 'appnexus', params: {placementId: '23075383'} },
          { bidder: 'criteo', params: { networkId: 8147 } },
          { bidder: 'ix', params: {siteId: 752383, size:[160, 600]} },
          //{ bidder: 'justpremium', params: {zone: 133775, allow: ["sa"]} },
          { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
          { bidder: 'openx', params: {unit: '556399844', delDomain: 'lqr-d.openx.net'} },
          //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_desktop_MPUBelowNext' } },
          { bidder: 'sovrn', params:{ tagid: 972307 } },
          { bidder: 'sovrn', params:{ tagid: 972306 } },
          { bidder: 'teads', params: {pageId: '139117', placementId: '152653'} },
          { bidder: 'triplelift', params: { inventoryCode: 'MotormagCOM_desktop_NEXT_LeftColumn_Prebid' } },
          //{ bidder: 'yieldone', params: {placementId: '133366'} },
          { bidder: 'sharethrough', params: {pkey: 'GwDQHaMHzEhZRHB5yBHBvaDC'} },
          { bidder: 'sharethrough', params: {pkey: 'pMIKJuajCStTvWJ6CSKSTFSS'} },
          { bidder: 'smartadserver', params: { siteId: 536189, pageId: 1662539, formatId: 116578} },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847481' } },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
          { bidder: 'taboola', params: { tagId: 'LeftColumn', publisherId: '1493286'} },
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' } }
        ]
      },
      {
        "condition": !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
        "id": "StickyBanner",
        "name": StickyBannerUnitName,
        "path": `/76730613/${StickyBannerPathway}`,
        "sizes": window.screen.width < 812 ? [[320, 50],[300,50],[360,50],[320,100],[300,100],[360,100]] : [[728, 90]],
        "bidders": [
          { bidder: 'adagio', params: { organizationId:'1067', site:'motormag-weekly-com', adUnitElementId: 'StickyBanner', environment:'desktop', placement:`${StickyBannerPathway}` } },
          { bidder: 'adform', params: { mid: 1182044 } },
          //{ bidder: 'appnexus', params: {placementId: '23075409'} },
          { bidder: 'criteo', params: { networkId: 8147 } },
          { bidder: 'ix', params: {siteId: 752387, size:[728, 90]} },
          ////{ bidder: 'justpremium', params: {zone: 133775} },
          { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
          { bidder: 'openx', params: {unit: '556399847', delDomain: 'lqr-d.openx.net'} },
          //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_desktop_MPUBelowNext' } },
          { bidder: 'sovrn', params:{ tagid: 972313 } },
          { bidder: 'teads', params: {pageId: '139117', placementId: '152653'} },
          { bidder: 'triplelift', params: { inventoryCode: 'MotormagCOM_desktop_NEXT_StickyBanner_Prebid' } },
          //{ bidder: 'yieldone', params: {placementId: '133370'} },
          { bidder: 'sharethrough', params: {pkey: 'Milfjjy5NHZ4I8o83tnXTA6I'} },
          { bidder: 'smartadserver', params: { siteId: 536189, pageId: 1662539, formatId: 116583} },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847481' } },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
          { bidder: 'taboola', params: { tagId: 'StickyBanner', publisherId: '1493286'} },
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' } }
        ],
        "mobile_bidders": [
          { bidder: 'adagio', params: { organizationId:'1067', site:'motormag-weekly-com', adUnitElementId: 'StickyBanner', environment:'mobile', placement:`${StickyBannerPathway}` } },
          { bidder: 'adform', params: { mid: 1182016 } },
         // { bidder: 'appnexus', params: { placementId: '23199209' } },
          { bidder: 'criteo', params: { networkId: 8147 } },
          { bidder: 'ix', params: {siteId: 752399, size: [320,50]} },
          { bidder: 'ix', params: {siteId: 752399, size: [300,50]} },
          { bidder: 'ix', params: {siteId: 752399, size: [360,50]} },
          { bidder: 'ix', params: {siteId: 752399, size: [320,100]} },
          { bidder: 'ix', params: {siteId: 752399, size: [300,100]} },
          { bidder: 'ix', params: {siteId: 752399, size: [360,100]} },
          //{ bidder: 'justpremium', params: {zone: 133775, allow: ["ms"]} },
          { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
          { bidder: 'openx', params: { unit: '556399825', delDomain: 'lqr-d.openx.net' } },
          //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_mobile_StickyBannerMobile' } },
          { bidder: 'sovrn', params:{ tagid: 972342 } },
          { bidder: 'sovrn', params:{ tagid: 972343 } },
          { bidder: 'sovrn', params:{ tagid: 972344 } },
          { bidder: 'teads', params: {pageId: '139117', placementId: '152653'} },
          { bidder: 'triplelift', params: {inventoryCode: 'MotormagCOM_mobile_NEXT_StickyBanner_Prebid'} },
         // { bidder: 'yieldone', params: {placementId: '133380'} },
          { bidder: 'sharethrough', params: {pkey: 'N6taX0lBkGf4vOam9gkTFLFW'} },
          { bidder: 'sharethrough', params: {pkey: 'aAC0k4LZAPZ5tNgffqyCnXHr'} },
          { bidder: 'smartadserver', params: { siteId: 536189, pageId: 1662539, formatId: 116600} },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847481' } },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
          { bidder: 'taboola', params: { tagId: 'StickyBanner', publisherId: '1493286'} },
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' } }
        ]
      },
      {
        "id": "MPUBelowNext",
        "name":  MPUBelowNextUnitName,
        "path": `/76730613/${MPUBelowNextPathway}`,
        "sizes": window.screen.width < 812 ? [[300, 250],[336,280],[320,100]] : [[300, 250],[336,280]],
        "bidders": [
          { bidder: 'adagio', params: { organizationId:'1067', site:'motormag-weekly-com', adUnitElementId: 'MPUBelowNext', environment:'desktop', placement:`${MPUBelowNextPathway}` } },
          { bidder: 'adform', params: { mid: 1182008 } },
         // { bidder: 'appnexus', params: {placementId: '23075406'} },
          { bidder: 'criteo', params: { networkId: 8147 } },
          { bidder: 'ix', params: {siteId: 752384, size:[300, 250]} },
          { bidder: 'ix', params: {siteId: 752384, size:[336, 280]} },
          ////{ bidder: 'justpremium', params: {zone: 133775} },
          { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
          { bidder: 'openx', params: {unit: '556399832', delDomain: 'lqr-d.openx.net'} },
          //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_desktop_MPUBelowNext' } },
          { bidder: 'sovrn', params:{ tagid: 972310 } },
          { bidder: 'sovrn', params:{ tagid: 972311 } },
          { bidder: 'teads', params: {pageId: '139117', placementId: '152653'} },
          { bidder: 'triplelift', params: { inventoryCode: 'MotormagCOM_desktop_NEXT_MPUBelowNext_Prebid' } },
         // { bidder: 'yieldone', params: {placementId: '133368'} },
          { bidder: 'sharethrough', params: {pkey: 'knVAqddR43jMYYmHjz7AOBNQ'} },
          { bidder: 'sharethrough', params: {pkey: 'yp3RU1mysSjgOhSIcrXOoXGj'} },
          { bidder: 'smartadserver', params: { siteId: 536189, pageId: 1662539, formatId: 116579} },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847481' } },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
          { bidder: 'taboola', params: { tagId: 'MPUBelowNext', publisherId: '1493286'} },
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' } }
        ],
        "mobile_bidders": [
          { bidder: 'adagio', params: { organizationId:'1067', site:'motormag-weekly-com', adUnitElementId: 'MPUBelowNext', environment:'mobile', placement:`${MPUBelowNextPathway}` } },
          { bidder: 'adform', params: { mid: 1182012 } },
          //{ bidder: 'appnexus', params: {placementId: '23075406'} },
          { bidder: 'criteo', params: { networkId: 8147 } },
          { bidder: 'ix', params: {siteId: 752394, size:[300, 250]} },
          { bidder: 'ix', params: {siteId: 752394, size:[336, 280]} },
          { bidder: 'ix', params: {siteId: 752394, size:[320, 100]} },
          ////{ bidder: 'justpremium', params: {zone: 133775} },
          { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
          { bidder: 'openx', params: {unit: '556399826', delDomain: 'lqr-d.openx.net'} },
          // //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_desktop_MPUBelowNext' } },
          { bidder: 'sovrn', params:{ tagid: 972334 } },
          { bidder: 'sovrn', params:{ tagid: 972335 } },
          { bidder: 'teads', params: {pageId: '139117', placementId: '152653'} },
          { bidder: 'triplelift', params: { inventoryCode: 'MotormagCOM_mobile_NEXT_MPUBelowNext_Prebid' } },
         // { bidder: 'yieldone', params: {placementId: '133377'} },
          { bidder: 'sharethrough', params: {pkey: 'F8kJOV5y7BqW53XOyxDDVEl8'} },
          { bidder: 'sharethrough', params: {pkey: 'knVAqddR43jMYYmHjz7AOBNQ'} },
          { bidder: 'sharethrough', params: {pkey: 'yp3RU1mysSjgOhSIcrXOoXGj'} },
          { bidder: 'smartadserver', params: { siteId: 536189, pageId: 1662539, formatId: 116596} },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847481' } },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
          { bidder: 'taboola', params: { tagId: 'MPUBelowNext', publisherId: '1493286'} },
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' } }
        ]
      },
      {
        "id": "MPUCenterRight",
        "name": MPUCenterRightUnitName,
        "path": `/76730613/${MPUCenterRightPathway}`,
        "sizes": window.screen.width < 812 ? [[300, 250],[336,280],[320,100], [1,1]] : [[300, 250],[336,280], [1,1]],
        "bidders": [
          { bidder: 'adagio', params: { organizationId:'1067', site:'motormag-weekly-com', adUnitElementId: 'MPUCenterRight', environment:'desktop', placement:`${MPUCenterRightPathway}` } },
          { bidder:  'adform', params: { mid: 1182009 } },
        //  { bidder: 'appnexus', params: { placementId: '23075457' } },
          { bidder: 'criteo', params: { networkId: 8147 } },
          { bidder: 'ix',  params: { siteId: 752385, size: [300, 250] } },
          { bidder: 'ix',  params: { siteId: 752385, size: [336, 280] } },
          //{ bidder: 'justpremium', params: {zone: 133775} },
          { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
          { bidder: 'openx', params: { unit: '556399833', delDomain: 'lqr-d.openx.net' } },
          ////{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_desktop_MPUCenterRight' } },
          { bidder: 'sovrn', params:{ tagid: 972308 } },
          { bidder: 'sovrn', params:{ tagid: 972309 } },
          { bidder: 'teads', params: {pageId: '139117', placementId: '152653'} },
          { bidder: 'triplelift', params: { inventoryCode: 'MotormagCOM_desktop_NEXT_MPUCenterRight_Prebid'} },
         // { bidder: 'yieldone', params: {placementId: '133367'} },
          { bidder: 'sharethrough', params: {pkey: 'cK14dxc7F6VwKXCsw3cjYPo2'} },
          { bidder: 'sharethrough', params: {pkey: 'mEt9b2XPj21NeyNPkModwGgg'} },
          { bidder: 'smartadserver', params: { siteId: 536189, pageId: 1662539, formatId: 116580} },
          //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847481' } },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
          { bidder: 'taboola', params: { tagId: 'MPUCenterRight', publisherId: '1493286'} },
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        ],
        "mobile_bidders": [
          { bidder: 'adagio', params: { organizationId:'1067', site:'motormag-weekly-com', adUnitElementId: 'MPUCenterRight', environment:'mobile', placement:`${MPUCenterRightPathway}` } },
          { bidder: 'adform', params: { mid: 1182014 } },
         // { bidder: 'appnexus', params: { placementId: '1182009' } },
          { bidder: 'criteo', params: { networkId: 8147 } },
          { bidder: 'ix',  params: { siteId: 752396, size: [300, 250] } },
          { bidder: 'ix',  params: { siteId: 752396, size: [336, 280] } },
          { bidder: 'ix',  params: { siteId: 752396, size: [320, 100] } },
          //{ bidder: 'justpremium', params: {zone: 133775, allow: ["is"]} },
          { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
          { bidder: 'openx', params: { unit: '556399837', delDomain: 'lqr-d.openx.net' } },
          //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_mobile_MPUCenterRight' } },
          { bidder: 'sovrn', params:{ tagid: 972339 } },
          { bidder: 'sovrn', params:{ tagid: 972340 } },
          { bidder: 'sovrn', params:{ tagid: 972341 } },
          { bidder: 'teads', params: {pageId: '139117', placementId: '152653'} },
          { bidder: 'triplelift', params: {inventoryCode: 'MotormagCOM_mobile_NEXT_MPUCenterRight_Prebid'} },
          //{ bidder: 'yieldone', params: {placementId: '133379'} },
          { bidder: 'sharethrough', params: {pkey: 'cK14dxc7F6VwKXCsw3cjYPo2'} },
          { bidder: 'sharethrough', params: {pkey: 'cK14dxc7F6VwKXCsw3cjYPo2'} },
          { bidder: 'sharethrough', params: {pkey: 'mEt9b2XPj21NeyNPkModwGgg'} },
          { bidder: 'smartadserver', params: { siteId: 536189, pageId: 1662539, formatId: 116597} },
          //{ bidder: 'ogury', params: { assetKey: 'OGY-7462D4C3F58E', adUnitId: 'c87bdaa0-17e3-013b-a60f-4fbd3db95f28', skipSizeCheck: true } },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847481' } },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
          { bidder: 'taboola', params: { tagId: 'MPUCenterRight', publisherId: '1493286'} },
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' } }
        ]
      }
    ];

  /**
   * Finds left and right columns, pushes the missing bidders
   * and pushes correct bidders to the adunit
   */
  // prettier-ignore
  if (window.screen.width > 1440) {
      let rcMissingIX = adUnits.find(ad => ad.id === 'RightColumn');
      let lcMissingIX = adUnits.find(ad => ad.id === 'LeftColumn');
      // Right
      rcMissingIX.bidders.push({ bidder: 'ix', params: {siteId: 657131, size:[300, 600]} });
      rcMissingIX.bidders.push({ bidder: 'ix', params: {siteId: 657131, size:[300, 250]} });
      rcMissingIX.bidders.push({ bidder: 'sovrn', params:{ tagid: 1103046 } });
      // Left
      lcMissingIX.bidders.push({ bidder: 'ix', params: {siteId: 674795, size:[300, 600]} });
      lcMissingIX.bidders.push({ bidder: 'ix', params: {siteId: 674795, size:[300, 250]} });
      lcMissingIX.bidders.push({ bidder: 'sharethrough', params: {pkey: '0W8fTWBgXh1f9uAU0rjL9iay'} });
      lcMissingIX.bidders.push({ bidder: 'sovrn', params:{ tagid: 1103048 } });
      
    }
  return adUnits;
}
